@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

html,
body {
  height: 100%;
}

body {
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}

#root {
  display: flex;
  min-height: 100%;
}

body {
  margin: 0;
  color: #000;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
